/* Only globals here! */

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans,
		Droid Sans, Helvetica Neue, sans-serif !important;
	color: #1a1a1a;
	font-size: 13px;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ul {
	margin: 0;
}

p {
	margin: 0 0 8px 0;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
	border-radius: 10px;
	overflow: hidden;
}

.DraftEditor-editorContainer {
	z-index: 0 !important;
}

blockquote {
	margin-left: 16px;
	padding-left: 16px;
	border-left: 2px solid #1a1a1a;
}

.public-DraftStyleDefault-pre {
	background: #eee;
	padding: 8px;
	margin: 4px;
}

.public-DraftStyleDefault-pre > pre {
	margin: 0;
}

a {
	color: inherit;
}

figure {
	margin: 4px;
}
